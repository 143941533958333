import { toTitleCase } from 'utils/shared/toTitleCase';
import VideoAnalysisNotificationTemplate from './VideoAnalysisNotificationTemplate';
import { VideoAnalysisForPlayerNotificationProps } from '../types';

const VideoAnalysisForPlayerNotification: React.FC<VideoAnalysisForPlayerNotificationProps> = ({
  status,
  notification,
}) => {
  const actorFullName = toTitleCase(
    notification?.notificationDetails?.primaryEntity?.actingUserProfile?.fullName || '',
  );

  return (
    <VideoAnalysisNotificationTemplate
      videoAnalysis={notification?.notificationDetails?.primaryEntity.videoAnalysis}
      status={status}
      actorFullName={actorFullName}
      badgeComponent={
        <div className="rounded-xl bg-red-600 px-2 text-xs leading-5 text-white">Pending</div>
      }
      message={`${actorFullName} has recieved your video review`}
    />
  );
};

export default VideoAnalysisForPlayerNotification;
