import { toTitleCase } from 'utils/shared/toTitleCase';
import VideoAnalysisNotificationTemplate from './VideoAnalysisNotificationTemplate';
import { VideoAnalysisCompletePlayerNotificationProps } from '../types';

const VideoAnalysisCompletePlayerNotification: React.FC<
  VideoAnalysisCompletePlayerNotificationProps
> = ({ status, notification }) => {
  const actorFullName = toTitleCase(
    notification?.notificationDetails?.primaryEntity?.actingUserProfile?.fullName || '',
  );

  return (
    <VideoAnalysisNotificationTemplate
      status={status}
      actorFullName={actorFullName}
      videoAnalysis={notification.notificationDetails.primaryEntity.videoAnalysis}
      badgeComponent={
        <div className="rounded-xl bg-red-600 px-2 text-xs leading-5 text-white">Completed</div>
      }
      message={`${actorFullName} completed their video review`}
    />
  );
};

export default VideoAnalysisCompletePlayerNotification;
